/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Manan's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Manan Shah Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/",
  },
};

//Home Page
const greeting = {
  title: "Manan Shah",
  logo_name: "MananShah",
  nickname: "",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1cIKRe0kp_ntzX-caT4YJyT_lbZBdfxcV/view?usp=sharing",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/mananshah3654",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/mananshah3654",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/mananshahdev/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/watch?v=inWxjvngXs8",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:mananshah3654@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/aplayermananfc",
    fontAwesomeIcon: "fa-brands fa-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/manan.shah.143/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/mananshah3654/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing sustainable architecture for fintech using Web APIs, Docker and container",
        "⚡ Creating application backend in angular, asp.net core and MVC",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "skill-icons:html",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "skill-icons:css",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "DotNet",
          fontAwesomeClassname: "skill-icons:dotnet",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "skill-icons:cs",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "skill-icons:javascript",
          style: {
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "skill-icons:jquery",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "AngularJS",
          fontAwesomeClassname: "skill-icons:angular-light",
          style: {
            color: "#CB3837",
          },
        },
        // {
        //   skillName: "Yarn",
        //   fontAwesomeClassname: "simple-icons:yarn",
        //   style: {
        //     color: "#2C8EBB",
        //   },
        // },
        // {
        //   skillName: "Gatsby",
        //   fontAwesomeClassname: "simple-icons:gatsby",
        //   style: {
        //     color: "#663399",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on Azure",
      ],
      softwareSkills: [
        // {
        //   skillName: "GCP",
        //   fontAwesomeClassname: "simple-icons:googlecloud",
        //   style: {
        //     color: "#4285F4",
        //   },
        // },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "SQL",
          imageSrc: "sql-server.png",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#47A248",
        //   },
        // },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    //   profileLink: "https://leetcode.com/layman_brother/",
    // },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Gujarat Technological University Ahmedabad",
      subtitle: "B.Tech. in Information and Technology",
      logo_path: "gtu_logo.png",
      alt_name: "GTU Ahmedabad",
      duration: "2009 - 2013",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Cloud Computing and Full Stack Development.",
        
      ],
      website_link: "https://www.gtu.ac.in/",
    },
    // {
    //   title: "Indiana University Bloomington",
    //   subtitle: "M.S. in Computer Science",
    //   logo_path: "iu_logo.png",
    //   alt_name: "Indiana University Bloomington",
    //   duration: "2021 - 2023",
    //   descriptions: [
    //     "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
    //     "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
    //     "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
    //   ],
    //   website_link: "https://www.indiana.edu/",
    // },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Fundamentals",
      subtitle: "- Microsoft Ltd.",
      logo_path: "azure_fundamentals.png",
      certificate_link:
        "https://www.credly.com/badges/c4042a4c-d8e7-44d5-a91a-543b3117ff6b/linked_in_profile",
      alt_name: "Microsoft",
      color_code: "#1F70C199",
    },
    {
      title: "Oracle Certified Associate",
      subtitle: "- Oracle",
      logo_path: "oca.png",
      certificate_link:
        "https://www.credly.com/badges/c241d181-6bf8-4eab-8e2e-c8dcc3f12792",
      alt_name: "Oracle",
      color_code: "#00000099",
    },
    {
      title: "Developer Associate",
      subtitle: "- Microsoft Ltd.",
      logo_path: "azure-developer-associate.png",
      certificate_link:
        "https://www.credly.com/badges/4ea8667b-52dd-4eac-b42e-5d7b4bb0846d/linked_in_profile",
      alt_name: "Microsoft",
      color_code: "#1F70C199",
    },
    {
      title: "AWS",
      subtitle: "- Stephane Maarek",
      logo_path: "aws.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-ba06e161-a667-4b04-a602-03b513f44b05/",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Azure",
      subtitle: "- Scott Duffy",
      logo_path: "azure.svg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-5f7153f4-e9cc-4f9e-b2c5-bd0bbf9cc472/",
      alt_name: "Azure",
      color_code: "#1F70C199",
    },
    {
      title: "Cloud Services",
      subtitle: "- Microsoft Learn",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://learn.microsoft.com/en-us/training/achievements/learn.wwl.describe-benefits-of-using-cloud-services.badge?username=MananShah-0191&sharingId=25D03F11363502B7",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
   
    // {
    //   title: "GitHub CoPilot on AIP",
    //   subtitle: "- Microsoft Training",
    //   logo_path: "github-copilot-logo.png",
    //   certificate_link:
    //     "https://learn.microsoft.com/en-us/training/achievements/learn.github.trophy?username=MananShah-0191&sharingId=25D03F11363502B7",
    //   alt_name: "Microsoft",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Volunteership",
  description:
    "I have worked with many evolving projects as Developer, Designer and Software Architect. I have also worked with some well established companies mostly as fullstack Developer. I love organising events and that is why I am also involved with many Web3 communities.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Technology Lead",
          company: "Infosys Ltd",
          company_url: "https://infosys.com",
          logo_path: "infosys_logo.png",
          duration: "July 2022 - Present",
          location: "Pune, Maharashtra",
          description:
            "Leading and mentoring a team of 5 developers, delivering top-notch software solutions. Acted as a liaison with clients, ensuring timely project delivery and effective communication. Utilized Agile methodologies for project success, developing and maintaining React, Core, and Azure applications. Achieved Azure Developer Associate certification, conducted interviews for talent acquisition, and managed multiple projects successfully.",
          color: "#0879bf",
        },
        {
          title: "Senior Software Analyst",
          company: "Radixweb",
          company_url: "https://radixweb.com/about-us",
          logo_path: "radix_logo.png",
          duration: "Aug 2021 - Jun 2022",
          location: "Ahmedabad, Gujarat",
          description:
            "Led diverse software app development, CRM, inventory management, billing platform. Collaborated with stakeholders, managed scope & timelines. Conducted code reviews, implemented Agile, facilitated hiring. Worked cross-functional teams for integration & issue resolution. Ensured best practices adherence.",
          color: "#0879bf",
        },
        {
          title: "Senior Software Developer SDE 2",
          company: "Mantra Softech",
          company_url: "https://www.mantratec.com/",
          logo_path: "mantra_logo.png",
          duration: "May 2019 - Aug 2021",
          location: "Ahmedabad, Gujarat",
          description:
            "Contributed to software design, development, and maintenance in an Agile setting. Proficient in C#, MVC, and JavaScript, delivering high-quality code. Actively involved in code reviews, API integration, debugging, and troubleshooting. Continuously learning to stay current with tech trends. Additionally, experienced in implementing algorithms for Face Detection.",
          color: "#0879bf",
        },
        {
          title: "Senior Developer",
          company: "National Informatics Center",
          company_url: "https://www.nic.in/",
          logo_path: "nic_logo-fotor-2023080718134.png",
          duration: "Dec 2015 - May 2019",
          location: "Gandhinagar, Gujarat",
          description:
            "I have created complete MVC Application for Pension Post department of Centeral Government. I have also worked on migration of databses from Oracle to PosgreSQL. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#0879bf",
        },
        {
          title: "Programmer",
          company: "Datasoft Complete IT- Solutions",
          company_url: "http://www.datasoftindia.org/aboutus.php",
          logo_path: "datasoft_logo.jpeg",
          duration: "Jun 2014 - Nov 2015",
          location: "Ahmedabad, Gujarat",
          description:
            "Embarking on my coding odyssey with Datasoft marked the genesis of my developer journey. Here, I honed core concepts and embraced the MVC paradigm. Leveraging C# ASP.NET and SQL, I crafted an innovative job posting project, epitomizing my passion for web development.",
          color: "#0879bf",
        },
        {
          title: "Proficient mentor",
          company: "Gujarat University",
          company_url: "https://www.gujaratuniversity.ac.in/",
          logo_path: "gu_logo.png",
          duration: "May 2023 - Present",
          location: "Ahmedabad, Gujarat",
          description:
            "As a tutor specializing in .NET MVC Core, my role encompasses not only imparting comprehensive knowledge but also shaping aspiring university students into capable individuals poised for a promising future in the field. Through rigorous instruction and practical application, I am dedicated to equipping students with the skills and insights necessary to excel in the dynamic world of software development.",
          color: "#fc1f20",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Machine Learning Intern",
    //       company: "TikTok Inc.",
    //       company_url: "https://www.tiktok.com/en/",
    //       logo_path: "tiktok_logo.png",
    //       duration: "May 2022 - Aug 2022",
    //       location: "San Francisco, USA",
    //       description:
    //         "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Data Science Research Intern",
    //       company: "Delhivery Pvt. Ltd.",
    //       company_url: "https://www.delhivery.com/",
    //       logo_path: "delhivery_logo.png",
    //       duration: "May 2019 - Sept 2019",
    //       location: "Gurgaon, Haryana",
    //       description:
    //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Data Science Intern",
    //       company: "Intel Indexer LLC",
    //       company_url:
    //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
    //       logo_path: "intel_logo.jpg",
    //       duration: "Nov 2018 - Dec 2018",
    //       location: "Work From Home",
    //       description:
    //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    {
      title: "Volunteerships",
      experiences: [
        // {
        //   title: "Google Explore ML Facilitator",
        //   company: "Google",
        //   company_url: "https://about.google/",
        //   logo_path: "google_logo.png",
        //   duration: "June 2019 - April 2020",
        //   location: "Hyderabad, Telangana",
        //   description:
        //     "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
        //   color: "#4285F4",
        // },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2013 - May 2014",
          location: "Ahmedabad, Gujarat",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        // {
        //   title: "Mozilla Campus Captain",
        //   company: "Mozilla",
        //   company_url: "https://www.mozilla.org/",
        //   logo_path: "mozilla_logo.png",
        //   duration: "Oct 2019 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
        //   color: "#000000",
        // },
        // {
        //   title: "Developer Students Club Member",
        //   company: "DSC IIITDM Kurnool",
        //   company_url:
        //     "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
        //   logo_path: "dsc_logo.png",
        //   duration: "Jan 2018 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
        //   color: "#0C9D58",
        // },
        // {
        //   title: "Developer Program Member",
        //   company: "Github",
        //   company_url: "https://github.com/",
        //   logo_path: "github_logo.png",
        //   duration: "July 2019 - PRESENT",
        //   location: "Work From Home",
        //   description:
        //     "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
        //   color: "#181717",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Courses",
  description:
    "I have worked on and published a few courses of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Practice Exams | AZ-900 Microsoft Azure Fundamentals",
      createdAt: "2023-04-01T16:26:54Z",
      description: "Master Azure fundamentals and enhance cloud expertise with hands-on practice.",
      url:
        "https://www.udemy.com/course/az900-azure-practice/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Practice Exams | AZ-204 Microsoft Azure Developing Solutions",
      createdAt: "2023-02-01T16:26:54Z",
      description: "Advance cloud development skills with the comprehensive designed to help you build and deploy applications on the Azure platform.",
      url:
        "https://www.udemy.com/course/az204-azure/",
    },
    
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Manan.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Asp.net, React, Cloud and Opensource Development.",
  },
  hobbySection: {
    title: "Footballer",
    profile_image_path: "Manan_fb.jpg",
    description:
      "As an avid football enthusiast, I have passionately honed my skills on the field, demonstrating exceptional teamwork and dedication. My commitment to continuous improvement drives me to excel both individually and collectively, making me an asset to any team.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blog.mananshah.dev/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
